import React, { useContext, useMemo, useState } from "react";
import { Button, ButtonGroup, Modal, Table } from "react-bootstrap";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import * as statusAPI from "api2/status-list";
import "./CompanyEventOverviewModal.scss";
import { StatusListStateContext } from "state/providers/StatusListProvider";
import useInitialAsync from "hooks/useInitialAsync";
import { Loader } from "components/ui/loaders";
import { formatMonth } from "utils/date";
import { prepareOverviewData } from "../helpers";
import OverviewEventSelect from "./OverviewEventSelect";

function YearSwitch({ selectedYear, setYear }) {
  const todayYear = new Date().getFullYear();
  const { t } = useTranslation("officeSupport");
  const years = [
    { value: todayYear - 1, label: t("prevYear") },
    { value: todayYear, label: t("currentYear") },
    { value: todayYear + 1, label: t("nextYear") },
  ];
  return (
    <ButtonGroup className="btn-switch mb-2">
      {years.map((year) => (
        <Button
          key={year.value}
          variant="outline-secondary"
          active={year.value === selectedYear}
          onClick={() => setYear(year.value)}
        >
          <i className="fe-check" /> {year.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}

function EventTd({ event, updateEvent }) {
  if (event.disabled) {
    return <td className="event-col disabled" />;
  }

  return (
    <td className={cx("event-col")} colSpan={event.colSpan}>
      <div className="top">
        <OverviewEventSelect
          event={event}
          current={event.statusIndicator.current}
          options={event.statusIndicator.statuses}
          updateEvent={updateEvent}
        />
      </div>
    </td>
  );
}

function CompanyEventOverviewTable({ data, updateEvent }) {
  const { t, i18n } = useTranslation();
  const isEnglish = useMemo(() => i18n.language === "en", [i18n.language]);
  return (
    <div className="overview-table">
      <Table bordered>
        <thead>
          <tr>
            <th />
            {data.months.map((month) => (
              <th key={month}>{formatMonth(month, "yyyy-MM")}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.values(data.templates).map((template) => (
            <tr key={template.id}>
              <th>{isEnglish ? template.name_en : template.name_sv}</th>
              {Object.values(template.months).map((event) => (
                <EventTd key={event.key} event={event} updateEvent={updateEvent} />
              ))}
            </tr>
          ))}
          {!Object.values(data.templates).length && (
            <tr>
              <td colSpan={data.months.length + 1}>{t("common:noResultsFound")}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

function CompanyEventOverviewModal({ companyId, companyName, handleClose }) {
  const {
    templates,
    filters: { section },
  } = useContext(StatusListStateContext);
  const { t } = useTranslation();
  const [year, setYear] = useState(new Date().getFullYear());

  const {
    loading,
    item: events,
    set,
  } = useInitialAsync(
    ({ cancelToken }) => statusAPI.events.overviewList(companyId, { section, date_year: year }, { cancelToken }),
    [],
    [year, section]
  );

  const updateEvent = (eventId, newEventData) => {
    set(
      events.map((event) => {
        if (event.id === eventId) {
          return { ...event, ...newEventData };
        }
        return event;
      })
    );
  };

  const data = useMemo(() => {
    if (loading) {
      return [];
    }
    return prepareOverviewData(templates, year, events);
  }, [loading, templates, year, events]);

  const onClose = () => {
    handleClose();
  };
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onClose}
      size="xl"
      dialogClassName="status-list-overview-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <div>
          <h1>{t("officeSupport:statusListOverview")}</h1>
          <h2>{companyName}</h2>
        </div>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 300 }}>
        <YearSwitch selectedYear={year} setYear={setYear} />
        {loading ? <Loader /> : <CompanyEventOverviewTable data={data} updateEvent={updateEvent} />}
      </Modal.Body>
    </Modal>
  );
}

export default CompanyEventOverviewModal;
