import React from "react";
import { NavLink, useResolvedPath, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HasPermCode } from "components/perms";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { useCompanyState } from "hooks/useCompany";
import { Button, ButtonGroup } from "react-bootstrap";
import "./InvoicingNavTabs.scss";

function InvoicingNavTabs() {
  const { t } = useTranslation("navigation");
  const path = useResolvedPath("").pathname;
  const navigate = useNavigate();
  const location = useLocation();
  const {
    company: { offer_order_enabled, contract_invoice_enabled },
  } = useCompanyState();

  const handleNavigation = (subPath) => {
    navigate(`${path}/${subPath}`);
  };

  const isActive = (subPath) => {
    return location.pathname.includes(`${path}/${subPath}`);
  };

  const customerRelatedPaths = ["customer-invoices", "rot-rut", "contract-invoices", "offer", "order"];
  const customerAndProductPaths = ["customers", "products"];
  const contractInvoicePaths = ["contract-invoices", "contract-templates"];

  return (
    <div className="d-flex justify-content-between">
      <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
        <li className="nav-item">
          <NavLink to={`${path}/customer-invoices`} className="nav-link">
            {t("customerInvoices")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={`${path}/rot-rut`} className="nav-link">
            {t("ci:rotRut")}
          </NavLink>
        </li>
        {HasPermCode(codesForCustomerInvoices.contractInvoices) && contract_invoice_enabled && (
          <li className="nav-item">
            <NavLink to={`${path}/contract-invoices`} className="nav-link">
              {t("contractInvoices")}
            </NavLink>
          </li>
        )}
        {HasPermCode(codesForCustomerInvoices.offerAndOrder) && offer_order_enabled && (
          <>
            <li className="nav-item">
              <NavLink to={`${path}/offer`} className="nav-link">
                {t("offer")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={`${path}/order`} className="nav-link">
                {t("order")}
              </NavLink>
            </li>
          </>
        )}
      </ul>
      <ul className="nav pt-3" role="tablist">
        <ButtonGroup>
          {HasPermCode(codesForCustomerInvoices.contractInvoices) && contract_invoice_enabled && (
            <Button
              className={`btn-custom ${isActive("contract-templates") ? "btn-custom-active" : ""}`}
              onClick={() => handleNavigation("contract-templates")}
            >
              <i className="fe-sidebar" /> {t("contractTemplates")}
            </Button>
          )}
          {(customerRelatedPaths.some((subPath) => isActive(subPath)) ||
            customerAndProductPaths.some((subPath) => isActive(subPath)) ||
            contractInvoicePaths.some((subPath) => isActive(subPath))) && (
            <>
              <Button
                className={`btn-custom ${isActive("customers") ? "btn-custom-active" : ""}`}
                onClick={() => handleNavigation("customers")}
              >
                <i className="fe-users" /> {t("customers")}
              </Button>
              <Button
                className={`btn-custom ${isActive("products") ? "btn-custom-active" : ""}`}
                onClick={() => handleNavigation("products")}
              >
                <i className="fe-tag" /> {t("products")}
              </Button>
            </>
          )}
        </ButtonGroup>
      </ul>
    </div>
  );
}

export default InvoicingNavTabs;
