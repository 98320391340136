import React, { useMemo } from "react";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ActivityChecklist } from "./StatusListChecklist";

function StatusListChecklistModal({ activity, companyId, handleClose, index }) {
  const { t, i18n } = useTranslation("officeSupport");
  const isEnglish = useMemo(() => i18n.language === "en", [i18n.language]);
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={handleClose}
      size="md"
      dialogClassName="status-list-config-modal checklist-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <div>
          <h1>{t("common:checklist")}</h1>
          <h2>{isEnglish ? activity.name_en : activity.name_sv}</h2>
        </div>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 300, paddingBottom: 16 }}>
        <ActivityChecklist activity={activity} companyId={companyId} index={index} />
      </Modal.Body>
    </Modal>
  );
}

export default StatusListChecklistModal;
