import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import heic2any from "heic2any";
import { humanFileSize } from "utils/text";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Loader } from "../loaders";

function Message({ message }) {
  return (
    <div className="dropzone-message">
      <i className="fe-upload-cloud" />
      <p>{message}</p>
    </div>
  );
}

function FilePreview({ base64data, fileType, rotation }) {
  const { t } = useTranslation("common");

  return (
    <div
      className={cx("dropzone-preview overflow-hidden", {
        "overflow-hidden": fileType === "application/pdf",
      })}
    >
      <object data={base64data} type={fileType} style={{ transform: `rotate(${rotation}deg)` }}>
        {t("errors.fileIsMissing")}
      </object>
    </div>
  );
}

function FileInfo({ file, onRemove }) {
  return (
    <div className="file-info">
      <div style={{ color: "red" }}>NEW</div>
      <div>{humanFileSize(file.size)}</div>
      <div>{file.type}</div>
      <div className="remove" onClick={(event) => onRemove(event)}>
        remove
      </div>
    </div>
  );
}

function DropZone({
  file,
  message,
  onFileSelected,
  onDragEnter,
  onDragOver,
  onDragLeave,
  onRemove,
  fileTypes,
  multiple = true,
  ...props
}) {
  const [data, setData] = useState({
    base64: "",
    type: "",
    rotation: 0,
  });
  const [processing, setProcessing] = useState(false);

  const onRotate = useCallback((event) => {
    event.stopPropagation();
    setData((state) => ({ ...state, rotation: state.rotation + 90 }));
  }, []);

  const fileRef = useRef(null);

  useEffect(() => {
    if (file) {
      if (file.type.includes("image/heif", "image/heic")) {
        setProcessing(true);
        heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.8,
          multiple: false,
        })
          .then((resultBlob) => {
            setData({
              base64: window.URL.createObjectURL(resultBlob),
              type: "image/jpeg",
              rotation: 0,
            });
          })
          .finally(() => {
            setProcessing(false);
          });
      } else {
        setData({
          base64: window.URL.createObjectURL(file), // String(e.target.result),
          type: file.type,
          rotation: 0,
        });
      }
    } else {
      setData({ base64: "", type: "", rotation: 0 });
    }
  }, [file]);

  return (
    <div
      className={cx("dropzone-wrapper", { "file-selected": !!file })}
      onClick={() => fileRef.current.click()}
      onDrop={onFileSelected}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      {...props}
    >
      {!file && <Message message={message} />}
      {processing && <Loader />}
      {file && (
        <FileInfo
          file={file}
          onRemove={(event) => {
            fileRef.current.value = "";
            onRemove(event);
          }}
          onRotate={onRotate}
        />
      )}
      {data.base64 && <FilePreview base64data={data.base64} fileType={data.type} rotation={data.rotation} />}
      <input ref={fileRef} type="file" accept={fileTypes} onChange={onFileSelected} multiple={!file && multiple} />
    </div>
  );
}

function DropZoneInput({ fileTypes, fileSize, onFileChange, initialFile, customValidate, multiple, ...props }) {
  const { t } = useTranslation("msg");
  const [message, setMessage] = useState("");

  function validate(selectedFile) {
    if (selectedFile.size > fileSize) {
      // max file size is 3mb
      toast.error(t("fileToBig", { max: (fileSize / (1024 * 1024)).toFixed(1) }), { autoClose: 4000 });
      return false;
    }
    if (customValidate) {
      return customValidate(selectedFile);
    }
    const extension = `.${selectedFile.name.split(".").pop().toLowerCase()}`;

    if (!fileTypes.includes(selectedFile.type) && !fileTypes.includes(extension)) {
      // allowed extension...
      console.warn("file type", selectedFile.type);
      toast.error(t("fileTypeNotSupported", { fileTypes }), {
        autoClose: 4000,
      });
      return false;
    }

    return true;
  }

  const onFileSelected = (event) => {
    event.preventDefault();
    const selectedFilesData = event.target.files || event.dataTransfer.files;
    let selectedFiles = Object.values(selectedFilesData);

    if (!selectedFiles.length) return null;

    if (!multiple && selectedFiles.length > 1) {
      toast.error(t("common:file.onlyOneAllowed"));
      selectedFiles = selectedFiles.slice(0, 1);
    }

    const validFiles = selectedFiles.filter((file) => validate(file));
    return validFiles.length ? onFileChange(validFiles) : setMessage(t("common:file.prompt"));
  };

  const onFileRemove = (event) => {
    event.stopPropagation();
    onFileChange(null);
  };

  return (
    <DropZone
      file={initialFile}
      fileTypes={fileTypes}
      message={message || t("common:file.prompt")}
      onFileSelected={onFileSelected}
      multiple={multiple}
      onDragEnter={(event) => {
        event.preventDefault();
        setMessage(t("common:file.drop"));
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setMessage(t("common:file.prompt"));
      }}
      onDragOver={(event) => event.preventDefault()}
      onRemove={onFileRemove}
      {...props}
    />
  );
}

const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/heif", ".heic", "application/pdf", "application/json"];
const COMPANY_ARCHIVE_ALLOWED_FILE_TYPES = [
  ...ALLOWED_FILE_TYPES,
  "text/xml",
  "text/csv",
  ".doc",
  ".docx",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "", // SIE files has not type...
];
const ALLOWED_FILE_SIZE = 1024 * 1024 * 10; // 10 MB

DropZoneInput.defaultProps = {
  fileTypes: ALLOWED_FILE_TYPES,
  fileSize: ALLOWED_FILE_SIZE,
  onFileChange: () => {},
};

export { ALLOWED_FILE_SIZE, ALLOWED_FILE_TYPES, COMPANY_ARCHIVE_ALLOWED_FILE_TYPES };
export default DropZoneInput;
