import React, { useContext, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { ButtonFilter, CheckboxFilter, DateRangeFilters, SearchFilter } from "components/filters";
import { SelectionContext } from "state/providers/SelectionProvider";

function RotRutFilters() {
  const { filters, setFilters } = useTable();
  const { clearSelection } = useContext(SelectionContext);
  const filtersCount =
    !!filters.booking_date__start +
    !!filters.booking_date__end +
    !!filters.payment_date__start +
    !!filters.payment_date__end +
    !!filters.is_ready_to_applied;
  const [more, setMore] = useState(!!filtersCount);
  const { t } = useTranslation("common");

  const handleFilters = (is_rot_rut_applied) => {
    if (is_rot_rut_applied === null) {
      clearSelection();
    }
    setFilters({ is_rot_rut_applied });
  };

  return (
    <div className="table-filters-group">
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <CheckboxFilter
          wrapperClass="hacked-checkbox"
          label={t("ci:advancedSearch")}
          name="advanced_search"
          defaultChecked={filters.advanced_search}
          onFilter={setFilters}
        />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <ButtonGroup aria-label="Status filter" className="status-filter">
          <Button
            variant="outline-secondary"
            active={filters.is_rot_rut_applied === ""}
            onClick={() => handleFilters("")}
          >
            {t("common:statuses.all")}
          </Button>
          <Button
            variant="outline-sent-bank"
            active={filters.is_rot_rut_applied === true}
            onClick={() => handleFilters(true)}
          >
            {t("common:statuses.applied")}
          </Button>
          <Button
            variant="outline-unpaid-expired"
            active={filters.is_rot_rut_applied === false}
            onClick={() => handleFilters(false)}
          >
            {t("common:statuses.unpaid")}
          </Button>
        </ButtonGroup>
      </section>
      {more && (
        <section className="more-filters">
          <DateRangeFilters
            label={t("common:dates.invoiceDate")}
            dateStart={filters.booking_date__start}
            dateEnd={filters.booking_date__end}
            onChange={({ start, end }) =>
              setFilters({
                booking_date__start: start,
                booking_date__end: end,
              })
            }
          />
          <DateRangeFilters
            label={t("common:dates.paymentDate")}
            dateStart={filters.payment_date__start}
            dateEnd={filters.payment_date__end}
            onChange={({ start, end }) => setFilters({ payment_date__start: start, payment_date__end: end })}
          />
          <ButtonFilter
            hiddenLabel
            helpText={t("ci:rotRutIsReadyToAppliedHelpText")}
            onFilter={(value) => setFilters({ is_ready_to_applied: value })}
            title={t("ci:rotRutIsReadyToApplied")}
          />
        </section>
      )}
    </div>
  );
}

export default RotRutFilters;
