import React, { useMemo, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { StepProgressBar } from "components/ui/progress";
import useInitialAsync from "hooks/useInitialAsync";
import { Loader } from "components/ui/loaders";
import DragProvider, { DraggableItem } from "state/providers/DragProvider";
import "./Checklist.scss";

function TextForm({ defaultValue = "", handleSubmit, handleReset }) {
  const { t } = useTranslation("common");
  const [text, setText] = useState(defaultValue);

  const onSubmit = () => {
    setText(defaultValue);
    if (handleSubmit) {
      if (text.trim() && text.trim() !== defaultValue.trim()) {
        handleSubmit(text);
      } else {
        handleReset();
      }
    }
  };

  const onReset = () => {
    setText(defaultValue);
    if (handleReset) {
      handleReset();
    }
  };

  return (
    <form className="edit-form">
      <div className="form-group">
        <input
          type="text"
          name="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="form-control"
          placeholder={t("common:actions.add")}
          autoComplete="off"
          autoCorrect="off"
        />
      </div>
      {(text || defaultValue) && (
        <div className="controls">
          <Button type="submit" variant="outline-gray" disabled={!text} onClick={onSubmit}>
            <i className="fe-check" />
          </Button>
          <Button className="ml-1" variant="outline-gray" onClick={onReset}>
            <i className="fe-x" />
          </Button>
        </div>
      )}
    </form>
  );
}

function Checkbox({ item, disabled, handleChange }) {
  const ref = useRef(null);
  const key = `text_${item.id}`;

  const onChange = (e) => {
    handleChange({ ...item, finished: e.target.checked });
  };

  return (
    <div className="checkbox checkbox-gray d-block" onClick={() => !disabled && ref.current.click()}>
      <input
        ref={ref}
        type="checkbox"
        id={key}
        name={key}
        onChange={onChange}
        disabled={disabled}
        checked={item.finished}
      />
      <label className="mb-0">&nbsp;</label>
    </div>
  );
}

function ChecklistItem({
  item,
  checkDisabled,
  inEditMode,
  handleRemove,
  handleChange,
  enableEditMode,
  disableEditMode,
  showControls,
}) {
  if (inEditMode) {
    return (
      <div className="checklist-item">
        <TextForm
          defaultValue={item.text}
          handleSubmit={(text) => handleChange({ ...item, text })}
          handleReset={disableEditMode}
        />
      </div>
    );
  }
  return (
    <div className="checklist-item">
      <Checkbox item={item} handleChange={handleChange} disabled={checkDisabled} />
      <div className={cx("text", { finished: item.finished })} onClick={enableEditMode}>
        {item.text}
      </div>
      {showControls && (
        <div className="item-controls" onClick={handleRemove}>
          <div className="remove">
            <i className="fe-trash" />
          </div>
          <div className="move">
            <i className="fe-more-vertical" />
          </div>
        </div>
      )}
    </div>
  );
}

function ChecklistWrapper({ dataSource, actions, checkDisabled = false, dragEnabled = false, showControls = false }) {
  const [editMode, setEditMode] = useState(null);
  const {
    loading,
    item: checklist,
    set: setChecklist,
  } = useInitialAsync(({ cancelToken }) => dataSource({ cancelToken }), [], [dataSource]);
  const currentProgress = useMemo(() => checklist.filter((item) => item.finished).length, [checklist]);

  const handleSwapItems = (originIndex, targetIndex) => {
    const newChecklist = [...checklist];
    const dragItemContent = newChecklist[originIndex];
    const dragOverItemContent = newChecklist[targetIndex];
    newChecklist[originIndex] = dragOverItemContent;
    newChecklist[targetIndex] = dragItemContent;
    setChecklist(newChecklist);
    actions.swap(dragItemContent.id, dragOverItemContent.id).catch(() => {});
  };

  const handleRemoveItem = (index, item) => {
    const newChecklist = [...checklist];
    newChecklist.splice(index, 1);
    setChecklist(newChecklist);
    actions.remove(item.id).catch(() => {});
    setEditMode(null);
  };

  const handleChange = (index, item) => {
    const newChecklist = [...checklist];
    newChecklist[index] = item;
    setChecklist(newChecklist);
    actions.update(item).catch(() => {});
    setEditMode(null);
  };

  const handleCreate = (text) => {
    actions.create(text).then((newItem) => {
      setChecklist([...checklist, { id: newItem.id, text, finished: false }]);
    });
  };

  const enableEditMode = (index, item) => {
    if (!showControls) {
      return;
    }
    if (!item.finished) {
      setEditMode(index);
    }
  };

  return (
    <div className="sl-checklist">
      {!checkDisabled && <StepProgressBar current={currentProgress} max={checklist.length} />}
      {showControls && <TextForm handleSubmit={handleCreate} />}
      {loading ? (
        <Loader />
      ) : (
        <Scrollbars style={{ height: 520 }}>
          <DragProvider handleSwapItems={handleSwapItems}>
            {checklist.map((item, index) => (
              <DraggableItem key={item.id} dragEnabled={dragEnabled && editMode === null} index={index}>
                <ChecklistItem
                  item={item}
                  checkDisabled={checkDisabled}
                  inEditMode={editMode === index}
                  enableEditMode={() => enableEditMode(index, item)}
                  disableEditMode={() => setEditMode(null)}
                  handleRemove={() => handleRemoveItem(index, item)}
                  handleChange={(newItem) => handleChange(index, newItem)}
                  showControls={showControls}
                />
              </DraggableItem>
            ))}
          </DragProvider>
        </Scrollbars>
      )}
    </div>
  );
}

export default ChecklistWrapper;
