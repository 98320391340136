import qs from "qs";
import React, { useContext, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useResolvedPath } from "react-router-dom";
import { formatISO } from "date-fns";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import useModal from "hooks/useModal";
import FileUrlModalOld from "components/modals/FileUrlModal/FileUrlModalOld";
import { CheckboxFilter, DateFilter, DateRangeFilters, SearchFilter } from "components/filters";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";
import { SelectionContext } from "state/providers/SelectionProvider";
import { formatDate } from "utils/date";
import MultiActionButton from "./MultiActionButton";

function CustomerInvoiceFilters({ companyId }) {
  const fbdModal = useModal();
  const csvModal = useModal();
  const { filters, setFilters } = useTable();
  const { clearSelection } = useContext(SelectionContext);
  const url = useResolvedPath("").pathname;
  const filtersCount =
    !!filters.booking_date__start +
    !!filters.booking_date__end +
    !!filters.due_date__start +
    !!filters.due_date__end +
    !!filters.flashback_date;
  const [more, setMore] = useState(!!filtersCount);
  const { t } = useTranslation("common");
  const show = {
    createBtn: !url.includes("/reports/"),
  };

  function openFlashbackPDF(fbd) {
    const param = formatISO(fbd, { representation: "date" });
    fbdModal.open(`/table/companies/${companyId}/customer-invoices/flashback_pdf/?flashback_date=${param}`);
  }

  const handleFilters = (mode) => {
    if (filters.mode === "open" || mode === "open") {
      clearSelection();
    }
    setFilters({ mode });
  };

  const formatDateFilters = () => {
    const formattedFilters = { ...filters };
    formattedFilters.booking_date__start = formatDate(formattedFilters.booking_date__start);
    formattedFilters.booking_date__end = formatDate(formattedFilters.booking_date__end);
    formattedFilters.due_date__start = formatDate(formattedFilters.due_date__start);
    formattedFilters.due_date__end = formatDate(formattedFilters.due_date__end);
    formattedFilters.flashback_date = formatDate(formattedFilters.flashback_date);
    return formattedFilters;
  };
  const openCSVModal = () => {
    const formattedFilters = formatDateFilters(filters);
    const params = qs.stringify(
      {
        ...formattedFilters,
      },
      { indices: false, skipNulls: true }
    );
    csvModal.open(`/table/companies/${companyId}/customer-invoices/report_csv/?${params}`);
  };
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <CheckboxFilter
          wrapperClass="hacked-checkbox"
          label={t("ci:advancedSearch")}
          name="advanced_search"
          defaultChecked={filters.advanced_search}
          onFilter={setFilters}
        />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <ButtonGroup aria-label="Status filter" className="status-filter">
          <Button
            variant="outline-secondary"
            active={!filters.mode || filters.mode === "all"}
            onClick={() => handleFilters("")}
          >
            {t("common:statuses.all")}
          </Button>
          <Button variant="outline-draft" active={filters.mode === "draft"} onClick={() => handleFilters("draft")}>
            {t("common:statuses.draft")}
          </Button>
          <Button
            variant="outline-unpaid-expired"
            active={filters.mode === "open"}
            onClick={() => handleFilters("open")}
          >
            {t("common:statuses.unpaid")}
          </Button>
          <Button variant="outline-paid" active={filters.mode === "paid"} onClick={() => handleFilters("paid")}>
            {t("common:statuses.paid")}
          </Button>
        </ButtonGroup>
      </section>
      <section className="table-filters-right">
        <PermCodeRequired code={codesForCustomerInvoices.manage}>
          <MultiActionButton companyId={companyId} mode={filters.mode} />
          {show.createBtn && (
            <Link to={`${url}/form`} className="btn btn-first">
              <i className="fas fa-plus" /> {t("common:actions.create")}
            </Link>
          )}
        </PermCodeRequired>
        <PermCodeRequired code={codesForCustomerInvoices.enabled}>
          <Button variant="outline-primary" onClick={openCSVModal}>
            {t("reports:reportCSV")}
          </Button>
        </PermCodeRequired>
      </section>
      {more && (
        <section className="more-filters">
          <DateFilter
            label={t("common:dates.ledgerDate")}
            defaultValue={filters.flashback_date}
            onChange={(date) => setFilters({ flashback_date: date })}
          >
            {filters.flashback_date && (
              <Button className="btn-block p-0" onClick={() => openFlashbackPDF(filters.flashback_date)}>
                PDF
              </Button>
            )}
          </DateFilter>
          <DateRangeFilters
            label={t("common:dates.invoiceDate")}
            dateStart={filters.booking_date__start}
            dateEnd={filters.booking_date__end}
            onChange={({ start, end }) =>
              setFilters({
                booking_date__start: start,
                booking_date__end: end,
              })
            }
          />
          <DateRangeFilters
            label={t("common:dates.dueDate")}
            dateStart={filters.due_date__start}
            dateEnd={filters.due_date__end}
            onChange={({ start, end }) => setFilters({ due_date__start: start, due_date__end: end })}
          />
        </section>
      )}
      {csvModal.show && (
        <FileUrlModalOld fileUrl={csvModal.data} downloadFileName="kundreskontra.csv" handleClose={csvModal.close} />
      )}
      {fbdModal.show && <FileUrlModalOld fileUrl={fbdModal.data} handleClose={fbdModal.close} />}
    </>
  );
}

export default CustomerInvoiceFilters;
