import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";
import { useResolvedPath } from "react-router-dom";

import {
  PreviewButton,
  TooltipActionButton,
  VerificationBookingButton,
  VerificationCommentsButton,
  VerificationDocumentsButton,
  HistoryButton,
} from "components/ui/buttons";
import { CIBookPaymentModal, FileUrlModal } from "components/modals";
import { SendInvoiceModal } from "components/modals/invoices";
import { confirmExecuteWithAction } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";
import * as ciAPIOld from "api/customer-invoices";
import * as ciApi from "api2/customer-invoices";
import useModal from "hooks/useModal";

import { SelectionContext } from "state/providers/SelectionProvider";

function RotRutActions({ row, companyId }) {
  const { t } = useTranslation("ci");
  const url = useResolvedPath("").pathname.replace("/rot-rut", "/customer-invoices");
  const bookPayModal = useModal();
  const sendModal = useModal();
  const xmlModal = useModal();
  const {
    tableId,
    dataActions: { reload },
  } = useTable();
  const { removeSelection } = useContext(SelectionContext);
  const show = {
    bookBtn: _.includes(["unpaid", "expired"], row.sub_status),
    bookingBtn: row.status === 1,
    editBtn: row.sub_status === "draft",
    deleteBtn: row.status === 0,
    reminderBtn: row.amount_sek > 0 && _.includes(["unpaid", "expired"], row.sub_status),
    rotRutXml: row.rot_rut_enabled === true && _.includes(["unpaid", "expired"], row.sub_status),
    sendAgainBtn: row.sub_status === "unpaid",
    moveDraftBtn: row.is_rot_rut_applied,
  };

  const xmlModalHandleClose = () => {
    reload();
    xmlModal.close();
  };

  const onBookPayModalOpen = () => {
    bookPayModal.open(row);
  };

  const onBookPayModalClose = (saved) => {
    bookPayModal.close();
    if (saved) {
      if (removeSelection) {
        removeSelection(tableId, row);
      }
      reload();
    }
  };

  const onInvoiceReminderSend = async () => {
    return confirmExecuteWithAction(t("confirm.sendReminder", { invoiceNo: row.invoice_no || row.ocr }), () => {
      return ciAPIOld
        .sendInvoiceReminder(companyId, row.id)
        .then(() => {
          toast.success(t("msg:sent"));
          reload();
        })
        .catch((error) => {
          if (error.data && error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    });
  };

  const onMoveBackFromApplied = async () => {
    return confirmExecuteWithAction(t("confirm.backFromApplied", { invoiceNo: row.invoice_no || row.ocr }), () => {
      return ciApi.rotRut
        .moveBackFromApplied(row.id, companyId)
        .then(() => {
          toast.success(t("msg:updated"));
          reload();
        })
        .catch((error) => {
          if (error.data && error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    });
  };
  const downloadRotRutXML = () => {
    xmlModal.open(`/customer-invoices/${row.id}/rot-rut/xml/`);
  };

  return (
    <>
      <HistoryButton apiResource={ciApi.history} apiParams={[companyId, row.id]} />
      <VerificationDocumentsButton verificationId={row.id} companyId={companyId} documentsIds={row.documents} />
      <VerificationCommentsButton companyId={companyId} verification={row} />
      <PreviewButton text={t("common:actions.preview")} link={`${url}/${row.id}/preview`} />
      {show.bookingBtn && <VerificationBookingButton companyId={companyId} verification={row} />}
      <PermCodeRequired code={codesForCustomerInvoices.bookPayment}>
        {show.bookBtn && (
          <TooltipActionButton
            text={t("common:actions.bookPayment")}
            onClick={onBookPayModalOpen}
            icon="fas fa-dollar-sign"
          />
        )}
      </PermCodeRequired>
      <PermCodeRequired code={codesForCustomerInvoices.manage}>
        {show.rotRutXml && (
          <TooltipActionButton
            text={t("actions.downloadRotRutXml")}
            variant="secondary"
            onClick={downloadRotRutXML}
            icon="fas fa-download"
          />
        )}
        {show.reminderBtn && (
          <TooltipActionButton
            text={t("actions.sendInvoiceReminder")}
            variant={row.reminder_sent_date ? "warning" : "secondary"}
            onClick={onInvoiceReminderSend}
            icon="fas fa-bell"
          />
        )}
        {show.sendAgainBtn && (
          <TooltipActionButton text={t("actions.sendAgain")} onClick={sendModal.open} icon="fas fa-paper-plane" />
        )}
      </PermCodeRequired>
      {bookPayModal.show && (
        <CIBookPaymentModal companyId={companyId} invoiceData={bookPayModal.data} handleClose={onBookPayModalClose} />
      )}
      {xmlModal.show && (
        <FileUrlModal companyId={companyId} fileUrl={xmlModal.data} handleClose={xmlModalHandleClose} autoDownload />
      )}
      {sendModal.show && <SendInvoiceModal handleClose={sendModal.close} companyId={companyId} invoiceData={row} />}
      {show.moveDraftBtn && (
        <TooltipActionButton
          variant="draft"
          text={t("common:actions.moveBackFromApplied")}
          onClick={onMoveBackFromApplied}
          icon="fas fa-reply"
        />
      )}
    </>
  );
}

export default RotRutActions;
