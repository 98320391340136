import client from "api2/client";

export function list(companyId, filters, config) {
  return client.get(`/assets/`, {
    params: filters,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function save(companyId, data, partialUpdateFor, config) {
  if (data.id) {
    if (!partialUpdateFor) {
      return client.put(`/assets/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.put(`/assets/${data.id}/update-${partialUpdateFor.split("_").join("-")}/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  }
  return client.post(`/assets/create/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function remove(companyId, assetId, params, config) {
  return client.delete(`/assets/${assetId}/delete/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function get(companyId, assetId, filters, config) {
  return client.get(`/assets/${assetId}`, {
    params: filters,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function previewAssetUpdates(companyId, assetId, data, partialUpdateFor, config) {
  return client.put(`/assets/${assetId}/preview-update-${partialUpdateFor.split("_").join("-")}/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function createExtraDepreciation(companyId, assetId, data, config) {
  return client.post(`/assets/${assetId}/create-extra-depreciation/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function sellPreview(companyId, assetId, data, config) {
  return client.put(`/assets/${assetId}/sell-preview/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function sell(companyId, assetId, data, config) {
  return client.put(`/assets/${assetId}/sell/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function decommissionPreview(companyId, assetId, data, config) {
  return client.put(`/assets/${assetId}/decommission-preview/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function decommission(companyId, assetId, data, config) {
  return client.put(`/assets/${assetId}/decommission/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function uploadDocuments(companyId, assetId, data, config) {
  return client.put(`/assets/${assetId}/decommission/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function uploadAsset(companyId, assetId, files) {
  const formData = new FormData();
  files.forEach((file) => {
    if (file) {
      formData.append("files", file, file.name);
    }
  });
  return client.post(`/assets/${assetId}/documents/upload/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-company": companyId,
    },
  });
}
