import qs from "qs";
import client from "api2/client";
import { formatDate, parseDate } from "utils/date";

export const templates = {
  list: (filters, config) => {
    return client.get(`/office-support/status-list/templates/`, {
      params: filters,
      ...config,
    });
  },
};
export const activities = {
  list: (companyId, config) => {
    return client.get(`/office-support/status-list/activities/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  generate: (companyId, startDate, config) => {
    return client.post(
      `/office-support/status-list/activities/generate/`,
      { start_date: startDate },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  reset: (companyId, config) => {
    return client.delete(`/office-support/status-list/activities/reset/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  updateMany: (companyId, data, config) => {
    return client.post(`/office-support/status-list/activities/update-many/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  deactivateActivity: (companyId, activityId, stepType, stepDate, config) => {
    return client.put(
      `/office-support/status-list/activities/${activityId}/deactivate/`,
      { step_type: stepType, step_date: stepDate && formatDate(stepDate) },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  activateActivityCheck: (companyId, activityId, startDate, periodicity, config) => {
    return client.get(`/office-support/status-list/activities/${activityId}/activation-check/`, {
      params: { start_date: startDate && formatDate(startDate), periodicity },
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  activateActivity: (companyId, activityId, data, config) => {
    return client.put(`/office-support/status-list/activities/${activityId}/activate/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  updateNote: (companyId, activityId, note, config) => {
    return client.put(
      `/office-support/status-list/activities/${activityId}/update-note/`,
      { note },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  checklist: {
    list: (companyId, activityId, config) => {
      return client.get(`/office-support/status-list/activities/${activityId}/checklist/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    create: (companyId, activityId, data, config) => {
      return client.post(`/office-support/status-list/activities/${activityId}/checklist/create/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    update: (companyId, activityId, itemId, body, config) => {
      return client.put(`/office-support/status-list/activities/${activityId}/checklist/${itemId}/update/`, body, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    delete: (companyId, activityId, itemId, config) => {
      return client.delete(`/office-support/status-list/activities/${activityId}/checklist/${itemId}/delete/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    prioritySwap: (companyId, activityId, originId, targetId, config) => {
      return client.put(
        `/office-support/status-list/activities/${activityId}/checklist/priority-swap/`,
        {
          origin_id: originId,
          target_id: targetId,
        },
        {
          headers: {
            "x-company": companyId,
          },
          ...config,
        }
      );
    },
  },
};
export const events = {
  list: (filters, config) => {
    return client.get(`/office-support/status-list/events/`, {
      params: filters,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
      ...config,
    });
  },
  overviewList: (companyId, filters, config) => {
    return client
      .get(`/office-support/status-list/events/overview/`, {
        params: filters,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
        headers: {
          "x-company": companyId,
        },
        ...config,
      })
      .then((response) => {
        response.data = response.data.map((event) => ({
          ...event,
          period_start: parseDate(event.period_start),
          period_end: parseDate(event.period_end),
        }));
        return response;
      });
  },
  updateStatus: (eventId, newStatus, config) => {
    return client.patch(`/office-support/status-list/events/${eventId}/update-status/`, { status: newStatus }, config);
  },
  updateAssignee: (eventId, newAssignee, config) => {
    return client.patch(
      `/office-support/status-list/events/${eventId}/update-assignee/`,
      { assignee: newAssignee },
      config
    );
  },
  comments: {
    list: (eventId, config) => {
      return client.get(`/office-support/status-list/events/${eventId}/comments/`, {
        params: {
          page_size: 100,
        },
        ...config,
      });
    },
    remove: (eventId, commentId) => {
      return client.delete(`/office-support/status-list/events/${eventId}/comments/${commentId}/delete/`);
    },
    create: (eventId, text, config) => {
      return client.post(`/office-support/status-list/events/${eventId}/comments/create/`, { text }, config);
    },
  },
  checklist: {
    list: (companyId, eventId, config) => {
      return client.get(`/office-support/status-list/events/${eventId}/checklist/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    toggleFinished: (companyId, eventId, activityChecklistItemId, config) => {
      return client.post(
        `/office-support/status-list/events/${eventId}/checklist/${activityChecklistItemId}/toggle-finished/`,
        null,
        {
          headers: {
            "x-company": companyId,
          },
          ...config,
        }
      );
    },
  },
};
