import React, { useCallback, useMemo } from "react";
import * as statusAPI from "api2/status-list";
import Checklist from "./Checklist";

function EventChecklist({ companyId, event }) {
  const dataSource = useCallback(
    ({ cancelToken }) => statusAPI.events.checklist.list(companyId, event.id, { cancelToken }),
    [companyId, event.id]
  );

  const actions = useMemo(
    () => ({
      update: (activityChecklistItem) =>
        statusAPI.events.checklist.toggleFinished(companyId, event.id, activityChecklistItem.id),
    }),
    [companyId, event.id]
  );

  return (
    <Checklist
      companyId={companyId}
      dataSource={dataSource}
      actions={actions}
      dragEnabled={false}
      showControls={false}
    />
  );
}

export default EventChecklist;
