import React, { useContext, useMemo, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Formik, Field, FieldArray } from "formik";
import _ from "lodash";
import { useCompanyState } from "hooks/useCompany";
import { SelectionContext } from "state/providers/SelectionProvider";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SubmitButton } from "components/ui/buttons";
import Headers from "components/tables/btable/Headers";
import { Loader } from "components/ui/loaders";
import { FormGroup, TableGroup } from "components/formik";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import * as documentAPI from "api/document";
import * as ciApi from "api2/customer-invoices";
import { formatDate } from "utils/date";
import "./RotRutModal.scss";
import cx from "classnames";
import AllError from "../../formik/AllError";

function RotRutModalTable({ data, tableId, onSubmit, formikProps, formikErrors }) {
  const { t } = useTranslation("company");
  const groupedData = data.reduce((acc, { application_name, ...item }) => {
    if (!acc[application_name]) {
      acc[application_name] = [];
    }
    acc[application_name].push(item);
    return acc;
  }, {});

  const { toggleSelection } = useContext(SelectionContext);
  const {
    paymentAccounts: { asOptions: paymentAccountsOptions },
  } = useCompanyState();

  const headers = useMemo(
    () => [
      { field: "invoice_no", label: t("common:invoiceNo"), canSort: false, width: "8%" },
      { field: "ssn", label: t("common:contact.personalNumber"), canSort: false, width: "12%" },
      { field: "customer_name", label: t("common:customer"), canSort: false, width: "23%" },
      { field: "approved_amount", label: t("ci:rotRutApprovedAmount"), canSort: false, width: "15%" },
      { field: "requested_amount", label: t("ci:rotRutRequestedAmount"), canSort: false, width: "15%" },
      { field: "left_to_pay", label: t("ci:rotRutLeftToPay"), canSort: false, width: "15%" },
      { field: "payment_date", label: t("common:dates.paymentDate"), canSort: false, width: "12%" },
    ],
    [t]
  );

  function toggleAll(values, setFieldValue) {
    const shouldSelectAll = values.cases.some((caseItem) => !caseItem.isDisabled && !caseItem.selected);

    values.cases.forEach((caseItem, index) => {
      if (!caseItem.isDisabled) {
        toggleSelection(tableId, `cases[${index}]`);
        setFieldValue(`cases[${index}].selected`, shouldSelectAll);
      }
    });
  }
  return (
    <Formik {...formikProps}>
      {({ values, setFieldValue, handleSubmit, errors, touched }) => (
        <>
          <AllError errors={formikErrors} />
          <Table className="rot-rut-matching-table">
            <thead>
              <Headers
                initialHeaders={headers}
                actionColumn={false}
                selectColumn
                onSelectAll={() => toggleAll(values, setFieldValue)}
                onSort={() => ""}
              />
            </thead>
            <tbody>
              <FieldArray
                name="cases"
                render={() =>
                  Object.keys(groupedData).map((application_name, groupIndex) => {
                    const rows = groupedData[application_name];
                    const totalApproved = rows.reduce((sum, row) => sum + parseFloat(row.approved_amount), 0);

                    return (
                      <React.Fragment key={`${application_name}-${groupIndex}`}>
                        <tr className="application-name">
                          <td colSpan="8">{application_name}</td>
                        </tr>
                        {rows.map((row, index) => {
                          const uniqueKey = `${application_name}-${index}-${row.id}`;
                          const caseIndex = values.cases.findIndex((c) => c.invoice_no === row.invoice_no);
                          const {
                            isDisabled,
                            selected,
                            hasMismatch,
                            hasApprovedVsRequestedMismatch,
                            hasApprovedVsLeftToPayMismatch,
                          } = values.cases[caseIndex];

                          const mismatchMessages = [];
                          if (hasApprovedVsRequestedMismatch) {
                            mismatchMessages.push(t("ci:approvedAmountMismatchRequestedAmountTooltip"));
                          }
                          if (!hasApprovedVsRequestedMismatch && hasApprovedVsLeftToPayMismatch) {
                            mismatchMessages.push(t("ci:approvedAmountMismatchLeftToPayTooltip"));
                          }

                          const approvedAmountTitle = () => {
                            if (!isDisabled) {
                              if (mismatchMessages.length > 0) {
                                return mismatchMessages.join("\n");
                              }
                            }
                            return t("ci:rotRutNoMatchFoundTooltip");
                          };

                          const requestedAmountTitle = () => {
                            if (isDisabled) {
                              return t("ci:rotRutNoMatchFoundTooltip");
                            }
                            if (hasApprovedVsRequestedMismatch) {
                              return t("ci:approvedAmountMismatchRequestedAmountTooltip");
                            }
                            return "";
                          };

                          const leftToPayTitle = () => {
                            if (isDisabled) {
                              return t("ci:rotRutNoMatchFoundTooltip");
                            }
                            if (!hasApprovedVsRequestedMismatch && hasApprovedVsLeftToPayMismatch) {
                              return t("ci:approvedAmountMismatchLeftToPayTooltip");
                            }
                            return "";
                          };

                          return (
                            <tr
                              key={uniqueKey}
                              className={isDisabled ? "disabled" : ""}
                              title={isDisabled ? t("ci:rotRutNoMatchFoundTooltip") : ""}
                            >
                              <td
                                className="selectable"
                                onClick={() => {
                                  if (!isDisabled) {
                                    toggleSelection(tableId, row);
                                    setFieldValue(`cases[${caseIndex}].selected`, !selected);
                                  }
                                }}
                              >
                                <div className="checkbox custom-control checkbox-primary">
                                  <Field
                                    type="checkbox"
                                    name={`cases[${caseIndex}].selected`}
                                    checked={selected}
                                    className="d-none"
                                    disabled={isDisabled}
                                  />
                                  <label className="custom-control-label" />
                                </div>
                              </td>
                              <td>{row.invoice_no}</td>
                              <td>{row.ssn}</td>
                              <td>{row.customer_name}</td>
                              <td
                                className={cx({
                                  warning: hasMismatch && !isDisabled,
                                })}
                                title={approvedAmountTitle()}
                              >
                                {parseFloat(row.approved_amount).toFixed(2)} SEK
                              </td>
                              <td
                                className={cx({
                                  warning: hasApprovedVsRequestedMismatch && !isDisabled,
                                })}
                                title={requestedAmountTitle()}
                              >
                                {parseFloat(row.requested_amount).toFixed(2)} SEK
                              </td>
                              <td
                                className={cx({
                                  warning:
                                    !hasApprovedVsRequestedMismatch && hasApprovedVsLeftToPayMismatch && !isDisabled,
                                })}
                                title={leftToPayTitle()}
                              >
                                {parseFloat(row.left_to_pay).toFixed(2)} SEK
                              </td>
                              {selected && !isDisabled ? (
                                <TableGroup.DatePicker
                                  name={`cases[${caseIndex}].payment_date`}
                                  popperClassName="popper-in-modal"
                                  isClearable={false}
                                  tdProps={{ className: "datepicker-input" }}
                                />
                              ) : (
                                <td />
                              )}
                            </tr>
                          );
                        })}
                        <tr className="total-approved">
                          <td colSpan="8">
                            {t("ci:rotRutTotalApproved")}: <strong>{totalApproved.toFixed(2)} SEK</strong>
                          </td>
                        </tr>
                        <tr className="empty">
                          <td colSpan="8" />
                        </tr>
                      </React.Fragment>
                    );
                  })
                }
              />
            </tbody>
          </Table>
          <Row>
            <Col lg={4}>
              <FormGroup.SimpleSelect
                name="payment_account"
                label={t("common:paymentAccount")}
                options={paymentAccountsOptions}
                helpText={t("company:helpText.paymentAccount")}
              />
            </Col>
          </Row>
          <Button variant="primary" onClick={handleSubmit} className="d-none" id="submitButton" />
        </>
      )}
    </Formik>
  );
}

function RotRutModal({ companyId, show, onHide }) {
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [formikErrors, setFormikErrors] = useState({});
  const [formikTouched, setFormikTouched] = useState({});
  const { t } = useTranslation("common");
  const { getFlatten, clearSelection } = useContext(SelectionContext);
  const selected = getFlatten();

  const {
    paymentAccounts: { byId: paymentAccountsById },
  } = useCompanyState();
  const getStepClass = (step) => {
    if (currentStep === step) {
      return "active";
    }
    if (currentStep > step) {
      return "completed";
    }
    return "";
  };

  const handleUploadSubmit = () => {
    setSubmitting(true);
    ciApi.rotRut
      .uploadSkvFile(companyId, newFiles)
      .then((response) => {
        setCurrentStep(response.data.step);
        setData(response.data.data);
      })
      .catch((error) => {
        setFormikErrors(error.data);
        toast.error(t("msg:fixErrors"));
        clearSelection();
      })
      .finally(() => {
        setSubmitting(false);
        setNewFiles([{ key: _.uniqueId("nd.") }]);
        clearSelection();
      });
  };

  const handleBookSubmit = (values) => {
    const selectedCases = values.cases.filter((c) => c.selected);
    const payload = {
      cases: selectedCases.map(({ id, payment_date, approved_amount }) => ({
        id,
        amount: approved_amount,
        payment_date: formatDate(payment_date),
        payment_account_id: values.payment_account.value,
      })),
    };

    ciApi.rotRut
      .bookPayments(companyId, payload)
      .then((response) => {
        toast.success(t("msg:saved"), { autoClose: 2000 });
        onHide();
        clearSelection();
      })
      .catch((error) => {
        if (error.data["cases.__all__"]) {
          toast.error(error.data["cases.__all__"], { autoClose: 4000 });
        }
        if (
          error.data["cases.0.payment_date"] ||
          error.data["cases.1.payment_date"] ||
          error.data["cases.2.payment_date"]
        ) {
          const errorMsg =
            error.data["cases.0.payment_date"] ||
            error.data["cases.1.payment_date"] ||
            error.data["cases.2.payment_date"];
          toast.error(errorMsg, { autoClose: 4000 });
        }
      });
  };

  const renderStepContent = () => {
    if (isSubmitting) {
      return <Loader />;
    }

    if (currentStep === 1) {
      return (
        <>
          <NewDocuments
            fileTypes={["application/json"]}
            documents={newFiles}
            multiple={false}
            onChange={({ file, index }) =>
              documentAPI.onNewFileChange({
                file,
                index,
                allFiles: newFiles,
                setFile: setNewFiles,
                max: 1,
              })
            }
          />
          <p />
          <div className="error-upload-message">
            <AllError errors={formikErrors} touched={formikTouched} />
          </div>
        </>
      );
    }
    if (currentStep === 2) {
      return (
        <RotRutModalTable
          data={data}
          tableId="rotRutModalTable"
          onSubmit={handleBookSubmit}
          formikErrors={formikErrors}
          formikProps={{
            initialValues: {
              payment_account: paymentAccountsById[1930],
              cases: data.map((row) => {
                const approvedAmount = parseFloat(row.approved_amount);
                const requestedAmount = parseFloat(row.requested_amount);
                const leftToPay = parseFloat(row.left_to_pay);
                const hasApprovedVsRequestedMismatch = approvedAmount !== requestedAmount;
                const hasApprovedVsLeftToPayMismatch = approvedAmount !== leftToPay;

                return {
                  id: row.id,
                  selected: !!row.id && !hasApprovedVsRequestedMismatch && !hasApprovedVsLeftToPayMismatch,
                  payment_date: null,
                  invoice_no: row.invoice_no,
                  ssn: row.ssn,
                  customer_name: row.customer_name,
                  approved_amount: row.approved_amount,
                  requested_amount: row.requested_amount,
                  left_to_pay: row.left_to_pay,
                  isDisabled: !row.id,
                  hasMismatch: hasApprovedVsRequestedMismatch || hasApprovedVsLeftToPayMismatch,
                  hasApprovedVsRequestedMismatch,
                  hasApprovedVsLeftToPayMismatch,
                };
              }),
            },
            onSubmit: handleBookSubmit,
            onChange: (touched) => setFormikTouched(touched),
          }}
        />
      );
    }
    return null;
  };
  const onHideHeader = () => {
    onHide();
    setSubmitting(false);
    setNewFiles([{ key: _.uniqueId("nd.") }]);
    setCurrentStep(1);
    setFormikErrors();
    clearSelection();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      scrollable
      dialogAs={DraggableModalDialog}
      className="upload-skv-file-modal"
    >
      <Modal.Header closeButton onHide={onHideHeader} />
      <Modal.Body>
        <ul className="stepper">
          <li className={getStepClass(1)}>
            <span>{t("ci:rotRutStepUploading")}</span>
            <div className="step-bar" />
          </li>
          <li className={getStepClass(2)}>
            <span>{t("ci:rotRutStepMatching")}</span>
            <div className="step-bar" />
          </li>
        </ul>
        <div className="step-upload-container">{renderStepContent()}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          onClick={() => {
            setSubmitting(false);
            setCurrentStep(1);
            onHide();
            setFormikErrors();
            clearSelection();
          }}
          className="step-upload-cancel-button"
        >
          {t("common:actions.cancel")}
        </Button>
        {currentStep === 1 ? (
          <SubmitButton
            onClick={handleUploadSubmit}
            icon="fa-upload"
            title={t("common:actions.upload")}
            variant="link"
            isSubmitting={isSubmitting}
          />
        ) : (
          <Button
            variant="primary"
            onClick={() => document.getElementById("submitButton").click()}
            title={!selected.length > 0 ? t("ci:bookPaymentSelectOne") : ""}
          >
            {t("common:actions.bookPayment")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default RotRutModal;
