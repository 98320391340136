import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function PasswordResetSuccessPage() {
  const { t } = useTranslation("others");
  return (
    <div className="box">
      <p className="font-16 text-white">{t("auth.introResetOk")}</p>
      <Link to="auth/standard-login">{t("common:actions.goBack")}</Link>
    </div>
  );
}

export default PasswordResetSuccessPage;
