import React, { useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import * as statusAPI from "api2/status-list";
import Checklist from "./Checklist";

function ActivityChecklist({ companyId, activity, index: configIndex }) {
  const { values, resetForm } = useFormikContext();
  const dataSource = useCallback(
    ({ cancelToken }) => statusAPI.activities.checklist.list(companyId, activity.id, { cancelToken }),
    [companyId, activity.id]
  );

  const configFormOnCreate = useCallback(
    (checklistItem) => {
      const activities = [...values.activities];
      activities[configIndex].checklist.push(checklistItem.id);
      resetForm({ values: { ...values, activities } });
    },
    [values, resetForm, configIndex]
  );

  const configFormOnDelete = useCallback(() => {
    const activities = [...values.activities];
    activities[configIndex].checklist.splice(0, 1);
    resetForm({ values: { ...values, activities } });
  }, [values, resetForm, configIndex]);

  const actions = useMemo(
    () => ({
      swap: (originItemId, targetItemId) =>
        statusAPI.activities.checklist.prioritySwap(companyId, activity.id, originItemId, targetItemId),
      remove: (itemId) =>
        statusAPI.activities.checklist.delete(companyId, activity.id, itemId).then(() => {
          configFormOnDelete();
        }),
      create: (text) =>
        statusAPI.activities.checklist
          .create(companyId, activity.id, {
            text,
            finished: false,
          })
          .then((response) => {
            const checklistItem = response.data;
            configFormOnCreate(checklistItem);
            return checklistItem;
          }),
      update: (item) =>
        statusAPI.activities.checklist.update(companyId, activity.id, item.id, {
          text: item.text,
          finished: item.finished,
        }),
    }),
    [companyId, activity.id, configFormOnCreate, configFormOnDelete]
  );

  return (
    <Checklist companyId={companyId} dataSource={dataSource} actions={actions} checkDisabled dragEnabled showControls />
  );
}

export default ActivityChecklist;
